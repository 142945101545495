import { ProjectModel } from "models/project_model";
import axios, { AxiosInstance } from "axios";
import { io } from "socket.io-client";
import { ChannelModel } from "models/channel_model";
import { FeedModel } from "models/feed_model";
import ProjectBody from "models/project_body";

class ApiClient {
  baseURL =
    window.location.port !== "3000"
      ? window.location.origin
      : `http://localhost:8080`;
  private readonly axiosInstance: AxiosInstance;

  feedSocket = io(`${this.baseURL}/feed`, {
    autoConnect: false,
  });

  projectSocket = io(`${this.baseURL}/projects`, {
    autoConnect: false,
  });

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL + "/api",
      timeout: 100000,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  setToken(token: string) {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }

  async loginWithPassword(password: string): Promise<string> {
    try {
      const response = await this.axiosInstance.post("/auth/login", {
        username: "admin",
        password: password,
      });
      return response.data.data.access_token;
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }

  async getProjects(): Promise<ProjectModel[]> {
    try {
      const response = await this.axiosInstance.get("/trackers/all-projects");
      const projectsList = response.data.data.projects;
      return projectsList;
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }

  async getProjectFeed(projectId: string, page: number, limit: number) {
    // cancel the previous request
    try {
      const response = await this.axiosInstance.get(`/feed/${projectId}`, {
        params: {
          page: page,
          limit: limit,
        },
      });
      return response.data.data;
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }

  async markFeedsAsRead(feeds: FeedModel[]) {
    try {
      const feedsList = feeds.map((feed) => feed.id);
      console.log("Marking feeds as read: ", feedsList);
      await this.axiosInstance.post("/feed/mark-read", {
        feedItems: feedsList,
      });
      console.log("Marked NewFeeds as read");
    } catch (e) {
      console.log("Error marking feeds as read", e);
    }
  }

  async getChannels(projectId: string): Promise<ChannelModel[]> {
    const response = await this.axiosInstance.get(
      `/trackers/all-channels/${projectId}`
    );
    return response.data.data.channels;
  }

  async addProject(
    projectName: string,
    projects: ProjectBody[]
  ): Promise<ProjectModel> {
    try {
      console.log("Adding project: ", projectName, projects);
      var response = await this.axiosInstance.post("/trackers/project", {
        projectName: projectName,
        channels: projects,
      });
      return response.data.data;
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        var data = "";
        if (typeof e.response.data.data === "string") {
          data = `, ${e.response.data.data}`;
        }
        throw new Error(`${e.response.data.message} ${data}`);
      }
      throw e;
    }
  }

  async updateProject(
    projectId: string,
    projectName: string,
    projects: ProjectBody[]
  ) {
    try {
      console.log("Updating project: ", projectName, projects);

      await this.axiosInstance.put("/trackers/project", {
        projectName: projectName,
        projectId: projectId,
        channels: projects,
      });
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }

  async untrackProject(projectId: string) {
    try {
      await this.axiosInstance.delete(`/trackers/project/${projectId}`);
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }

  async syncFcmDeviceToken(fcmToken: string) {
    try {
      await this.axiosInstance.get(`/notifications/token/${fcmToken}`);
      console.log(`Synced FCM Device Token: ${fcmToken}`);
    } catch (e) {
      if (e.response && e.response.data) {
        console.log(`FcmSyncError: ${e.response.data.message}`);
      } else {
        console.log("Error syncing FCM Device Token: ", e);
      }
    }
  }
}

/// Export a singleton instance of the ApiClient
export const apiClientInstance = new ApiClient();
