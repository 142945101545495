import React, { useEffect } from "react";
import "./styles/color.css";
import "./styles/index.css";
import { RoutePath } from "./helper/route_paths";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";
import NotFound from "pages/not_found";
import authProviderInstance from "backend/auth_provider";
import { createRoot } from 'react-dom/client';
import { apiClientInstance } from "backend/api_provider";

import { getFirebaseToken, messaging } from "./firebase";
import { isSupported, MessagePayload, onMessage } from "firebase/messaging";


async function requestNotificationPermissionAndSetListener() {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  }
  // Request permission to receive notifications
  const permission = await Notification.requestPermission();
  if (permission === "denied") {
    alert("Notification permission is denied. Please enable it to receive notifications");
    return;
  }

  console.log("Notification permission granted: ", permission);
  // Check if the browser supports notifications
  const supported = await isSupported();
  if (!supported) {
    console.log("Notifications are not supported in this browser. Please use a different browser");
    return;
  }

  // Register a message listener
  onMessage(messaging, (payload: MessagePayload) => {
    console.log("Notification received. ", payload);
    let notificationData = payload.notification;
    let tapActionUrl = payload.data.url;
    if (!notificationData) return;

    // check if web app is in the foreground
    // if (document.visibilityState === "visible") {
    //   console.log("Web app is in the foreground. Not displaying notification");
    //   return;
    // }

    const notification = new Notification(notificationData.title, {
      icon: notificationData.icon,
      body: notificationData.body,
    })
    notification.onerror = () => {
      console.log("An error occurred while displaying the notification");
    }
    notification.onclick = () => {
      notification.close();
      if (tapActionUrl) {
        window.open(tapActionUrl, "_blank");
      }
      console.log("Notification clicked");
    };
  });

  getFirebaseToken().then((firebaseToken: string | undefined) => {
    if (firebaseToken) {
      // Send the token to the server
      apiClientInstance.syncFcmDeviceToken(firebaseToken);
    }
  }).catch((error) => {
    console.log("An error occurred while retrieving token. ", error);
  });
}

const DashboardPage = React.lazy(() => import("pages/dashboard_page/dashboard"));
const Login = React.lazy(() => import("pages/login/login"));

const App: React.FC = () => {
  const [isAuth, setIsAuth] = React.useState(authProviderInstance.isAuthenticated());

  useEffect(() => {
    requestNotificationPermissionAndSetListener();
  }, []);


  // Define the routes
  const LoginRoute = () => {
    return <Login
      onLogin={() => {
        setIsAuth(true);
      }} />
  };

  const DashboardRoute = () => isAuth ? <DashboardPage /> : <Navigate to={RoutePath.LOGIN} />

  const RootRoute = () => {
    const params = useParams();
    return isAuth ? <Navigate to={`${RoutePath.DASHBOARD}/${params['*']}`} /> : <Navigate to={RoutePath.LOGIN} />;
  };

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/*" element={<RootRoute />} />
          <Route path={RoutePath.LOGIN} element={LoginRoute()} />
          <Route path={`${RoutePath.DASHBOARD}/*`} element={DashboardRoute()} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};


createRoot(
  document.getElementById('root')
).render(<App />);
