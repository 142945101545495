export class Config {
  static cloudFunctionKey =
    "BMltTpVPz8Lk7RP_MOVbrMPahNiw_LW-hRFm45p2QjRDeUY9kvPbHCnZLPQnjNqeGpHUxS6RqEqKIysao2LYkf8";

  // Firebase configuration, also used in public/firebase-messaging-sw.js
  static firebaseConfig = {
    apiKey: "AIzaSyA0g17wb3YV5AYP1xDSW42Uxfciwz9nuXI",
    authDomain: "web3tracker-d5e28.firebaseapp.com",
    projectId: "web3tracker-d5e28",
    storageBucket: "web3tracker-d5e28.appspot.com",
    messagingSenderId: "1034706480943",
    appId: "1:1034706480943:web:c2426c567cda56cdfd3bda",
  };
}
