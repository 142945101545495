import { Config } from "config";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";


export const firebaseApp = initializeApp(Config.firebaseConfig);

export const messaging = getMessaging(firebaseApp);

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = async () => {
    if (
        "serviceWorker" in navigator &&
        typeof window.navigator.serviceWorker !== "undefined"
    ) {
        return window.navigator.serviceWorker
            .getRegistration("/firebase-push-notification-scope")
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register(
                    "/firebase-messaging-sw.js",
                    {
                        scope: "/firebase-push-notification-scope",
                    }
                );
            });
    }
    throw new Error("The browser doesn`t support service worker.");
};

// getFirebaseToken function generates the FCM token 
export const getFirebaseToken = async () => {
    try {
        return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
            return Promise.resolve(
                getToken(messaging, {
                    vapidKey: Config.cloudFunctionKey,
                    serviceWorkerRegistration,
                })
            );
        });
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }
};