import { apiClientInstance } from "./api_provider";

class AuthProvider {
  tokenKey = "project_tracker_token";

  constructor() {
    apiClientInstance.setToken(this.authToken() ?? "");
  }

  authToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  isAuthenticated(): boolean {
    return this.authToken() !== null;
  }

  async login(password: string): Promise<boolean> {
    const token = await apiClientInstance.loginWithPassword(password);
    console.log(token);
    localStorage.setItem(this.tokenKey, token);
    apiClientInstance.setToken(token);
    return true;
  }

  async logout() {
    localStorage.removeItem(this.tokenKey);
    apiClientInstance.setToken("");
  }
}

const authProviderInstance = new AuthProvider();
export default authProviderInstance;
